<template>
  <div>
    <Plotly :data="data" :layout="layout" :scrollZoom="true" :displaylogo="false" :modeBarButtonsToRemove="['resetScale2d', 'hoverCompareCartesian', 'hoverClosestCartesian']"></Plotly>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { Plotly } from 'vue-plotly'

export default {
  name: 'resultsGraphSPL1',
  components: {
    Plotly
  },
  props: {
    height: Number,
    selected_file: Number
  },
  data () {
    return {
      data: [
        // ----- Gráfico de archivo seleccionado ------
        {
          z: [],
          type: 'heatmap',
          colorscale: [
            ['0.0', 'rgb(255,255,255)'],
            ['0.3', 'rgb(200,200,200)'],
            ['0.5', 'rgb(160,160,160)'],
            ['0.7', 'rgb(200,200,200)'],
            ['1.0', 'rgb(255,255,255)']
          ],
          colorbar: {
            title: 'Percentiles',
            //x: .95, 
            //y: .23,
            //len: 0.5
            //thickness: 10
          },
          hovertemplate: 'Percentil: %{z:.2f}<extra></extra>',
          zsmooth: 'best'
        }
        // ----- Gráfico con la media de los wavs ------
      ],
      layout:{
        legend: {
          orientation: 'h',
          y: 1.1
        },
        yaxis: {
          //autorange: 'reversed',
          title: {
            text: 'SPL [dB re 1μPa]'
          },
          // type: 'log',
          // autorange: true,
          // showexponent: 'all',
          // exponentformat: 'power'
        },
        xaxis: {
          title: {
            text: 'Frequency [Hz]'
          },
          type: 'log',
          autorange: true,
          showexponent: 'all',
          exponentformat: 'power'
        },
        plot_bgcolor:"#FFFFFF",
        paper_bgcolor:"#FFF3",
        dragmode: 'pan',
        modebar: {
          bgcolor: 'rgba(0,0,0,0)',
          color: '#555555',
          activecolor: '#bbbbbb',
          orientation: 'v'
        },
        height: 340,
        margin: {
          t: 10,
          r: 160
        },
        hoverlabel: {
          font: {
            color: 'white'
          }
        }
      },
      y_min: 0,
      y_max: 0
    } 
  },
  computed: {
    ...mapGetters({
      results_SPL: 'dsptool/results_SPL'
    }),
  },
  created () {
    this.layout.height = this.height
    this.layout.width = this.layout.width - 10
  },
  mounted () {
    this.drawGraph()
    this.drawSubplots()
  },
  watch: {
    selected_file: function () {
      // Do nothing
    },
  },
  methods: {
    drawGraph: function () {
      let ejeY = []
      for (let i = 0; i < this.results_SPL.Merchant_Y.length; i++){
        ejeY.push(this.results_SPL.Merchant_Y[i][0])
      }
      this.data[0].x = this.results_SPL.Merchant_X[0]
      this.data[0].y = ejeY
      this.data[0].z = this.results_SPL.Merchant_Z
    },
    drawSubplots: function () {
      // Media
      let subplotMedia = {}
      subplotMedia.x = this.results_SPL.freq_SPL
      subplotMedia.y = this.results_SPL.SPLmedia
      subplotMedia.name = 'Mean'
      subplotMedia.hoverinfo = 'skip'
      let line = {}
      line.color = 'rgba(30, 30, 30, 1)'
      line.width = 1.25
      subplotMedia.line = line
      
      this.data.push(subplotMedia)



      let listaPercentiles = [5, 10, 50, 90, 95]
      listaPercentiles.forEach(percentil => {
        
        let subplot = {}
        subplot.x = this.results_SPL.freq_SPL
        subplot.y = this.results_SPL.percentiles[percentil]
        subplot.name = 'L ' + percentil
        subplot.hoverinfo = 'skip'
        let line = {}
        //line.color = 'rgba(55, 210, 242, 0.7)'
        line.width = 1
        subplot.line = line
        
        this.data.push(subplot)

      });
    }
  }
}
</script>