<template>
  <div>
    <div class="card-header-1">
      <div class="card-header-title">WELCOME</div>
      <div class="close-inputs" @click="closeInputs"></div>
    </div>
    <div class="card-body-1 fixed-height">
      <div class="row h-100">
        <div class="col-sm-1 my-auto text-center input-arrow"> <!--&lt;--> </div>
        <div class="col-sm-10">
          <div class="row text-center">
            <div class="col-sm-12 ">
              <navigation></navigation>
            </div>
          </div>

          <div class="row h-75 form-group d-flex justify-content-center">
            <div class=" col-sm-4 my-auto">
              <h3 class="input-label d-flex justify-content-center" style="margin-bottom: 2rem;">Name of the processing job</h3>
              <input class="general-input form-control" placeholder="Name" type="text" v-model="name">
            </div>
          </div>
        </div>
        <div class="col-sm-1 my-auto text-center input-arrow" v-on:click="navigate(2)">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
            <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Vuex
import { mapGetters } from "vuex";

// Components and services
import navigation from '@/components/process-steps/navigation.vue'
import { required, decimal, maxValue } from 'vuelidate/lib/validators'
// import { getBathymetry } from '@/services/api'

export default {
  name: 'input1bienvenido',
  components: {
    navigation
  },

  computed: {
    ...mapGetters({
      status: 'ui/status', 
      current_interface: 'ui/current_interface',
      // pos_emitter: 'urn/pos_emitter',
      //validations: 'urn/validations'
    }),
      name: {
          set (data) { this.$store.dispatch('dsptool/change_name', data); },
          get () { return this.$store.state.dsptool.formInputs.name }
    },
  },
  data() {
    return {
      // max_depth: null   
    }
  },
  validations: {
  }, 
  methods: {
    navigate: function (data) {
      this.$store.dispatch('ui/change_current_interface', data)
    },
    closeInputs: function () {
      this.$store.dispatch('ui/change_current_interface', 0)
    }
    // setName: function (data) {
    //   this.$store.dispatch('dsptool/set_Name', data.target.value)
    // },
    // setLongitude: function (data) {
    //   this.$store.dispatch('dsptool/set_Longitude', data.target.value)
    // }
  }
}
</script>