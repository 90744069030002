import axios from 'axios'
import * as localData from '@/services/localData.js'
import Vue from 'vue'
import { postData } from "@/services/api";


const state = () => ({
  status: '',
  auth_token: localData.getlocal('auth_token') || '',
  refresh_token:localData.getlocal('refresh_token') || '',
  username : localData.getlocal('username') || '',
  fullname: localData.getlocal('fullname') || '',
  user_id: localData.getlocal('user_id') || '',
  email: localData.getlocal('email') || '',
  roles: localData.getArraylocal("roles") || [],
})

const mutations = {
  auth_request(state){
    state.status = 'loading'
  },
  auth_success(state, data){
    state.status = "success";
    state.auth_token = data.token;
    state.refresh_token = data.refreshToken;
    state.username = data.userName;
    state.fullname = data.fullName;
    state.user_id = data.tokenParsed.sub;
    state.email = data.tokenParsed.email;
    state.roles = data.realmAccess.roles;
  },
  auth_error(state){
    state.status = 'error'
  },
  logout(state){
    state.status = "";
    state.auth_token = "";
    state.refresh_token = "";
    state.username = "";
    state.fullname = "";
    state.user_id = "";
    state.email = "";
    state.roles = "";
  },
  auth_validate(state, auth_token){
    state.status = 'success'
    state.auth_token = auth_token
  }
}

const actions = {
  login({commit}, user){
    return new Promise((resolve, reject) => {
      commit('auth_request')
      postData('auth-jwt/login/',user)
      .then(resp => {
        if(resp.data.access){
          const data = {
            auth_token : resp.data.access,
            refresh_token : resp.data.refresh,
            username : resp.data.username,
            user_id : resp.data.id,
            is_admin : resp.data.is_admin,
            first_name : resp.data.first_name,
            last_name : resp.data.last_name,
            email : resp.data.email,
          }
          localData.setlocalToken('auth_token',data.auth_token)
          localData.setlocal('refresh_token',data.refresh_token)
          localData.setlocal('username',data.username)
          localData.setlocal('user_id',data.user_id)
          localData.setlocal('is_admin',data.is_admin)
          localData.setlocal('first_name',data.first_name)
          localData.setlocal('last_name',data.last_name)
          localData.setlocal('email',data.email)

          commit('auth_success',data)
          Vue.noty.success('Welcome '+ data.username);
          resolve(resp)
        }
        else{
          commit('auth_error')
          localData.deleteAll()
          if(resp.status == 403){
            Vue.noty.error('Reached maximum number of concurrent users.')
          }else {
            Vue.noty.error('Incorrect password or user.')
          }
          
        }
      })
      .catch(error => {
        commit('auth_error')
        console.log(error)
        Vue.noty.error('Incorrect password or user.')
        localData.deleteAll()
        reject(error)
      })
    })
  },
  setUserParams({ commit },user) {
    return new Promise((resolve, reject) => {    
      //console.log(user)
      localData.setlocalToken("auth_token", user.token);
      localData.setlocal("refresh_token", user.refreshToken);
    
      if (localStorage.username != user.userName){
        localData.setlocal("username", user.userName);
        localData.setlocal("fullname", user.fullName);
        localData.setlocal("user_id", user.tokenParsed.sub);
        localData.setlocal("email", user.tokenParsed.email);
        localData.setlocal("roles", user.realmAccess.roles);

        commit("auth_success", user);
      }
    });
  },
  logout({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$keycloak.logoutFn()
      commit("logout");
      localData.deleteAll();
      //toaster_info("Goodbye");
      Vue.noty.success("Goodbye")
      resolve("OK");
    });
  }
}

 const getters = {
    isLoggedIn: state => !!state.auth_token,
    authStatus: state => state.status,
    token: state => state.auth_token,
    username: state => state.username,
    user_id: state => state.user_id,
    is_admin: state => state.is_admin,
    fullname: state => state.fullname,
    email: state => state.email,
  }

  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }