<template>
  <footer>
    2022 © Centro Tecnológico Naval y del Mar - &nbsp; <a href="#" style="margin-left: 6px;">Portal Web</a>
  </footer>
</template>

<script>
export default {
  name: 'dsptFooter',
  data () {
    return {
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang= "scss" scoped>

  footer{
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #3f3f3f;
    color: #a3a3a3;
    padding-left: 30px;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
  }

  a {
    color: $nav-text;
  }
  a:hover {
    color: white;
  }
</style>
