<template>
  <div>
    <Plotly :data="data" :layout="layout" :scrollZoom="true" :displaylogo="false" :modeBarButtonsToRemove="['resetScale2d', 'hoverCompareCartesian', 'hoverClosestCartesian']"></Plotly>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { Plotly } from 'vue-plotly'

export default {
  name: 'resultsGraphD111',
  components: {
    Plotly
  },
  props: {
    height: Number,
    selected_file: Number
  },
  data () {
    return {
      data: [
        // ----- Gráfico Media ------
        {
          x: [],
          y: [],
          name: '10Hz - 10KHz',
          mode: 'lines',
          hoverinfo: 'skip',
          line: {
            color: 'rgba(55, 210, 242, 0.7)',
            width: 1
          },
        }
      ],
      layout:{
        showlegend: true,
        yaxis: {
          //autorange: 'reversed',
          title: {
            text: 'SPL [dB re 1μPa]'
          },
          // type: 'log',
          // autorange: true,
          // showexponent: 'all',
          // exponentformat: 'power'
        },
        xaxis: {
          title: {
            text: 'Time [s]'
          },
          // type: 'log',
          // autorange: true,
          // showexponent: 'all',
          // exponentformat: 'power'
        },
        plot_bgcolor:"#052030",
        paper_bgcolor:"#FFF3",
        dragmode: 'pan',
        modebar: {
          bgcolor: 'rgba(0,0,0,0)',
          color: '#555555',
          activecolor: '#bbbbbb',
          orientation: 'v'
        },
        height: 340,
        margin: {
          t: 10,
          r: 160
        },
        hoverlabel: {
          font: {
            color: 'white'
          }
        }
      },
      y_min: 0,
      y_max: 0
    } 
  },
  computed: {
    ...mapGetters({
      results_D11: 'dsptool/results_D11',
      fileNames: 'dsptool/fileNames'
    }),
  },
  created () {
    this.layout.height = this.height
  },
  mounted () {
    this.drawGraph()
  },
  watch: {
    // selected_file: function () {
    //   this.drawGraph()
    // },
  },
  methods: {
    drawGraph: function () {
      if(this.fileNames.length < 2) {
        this.data = []
        let subplot = {}
        subplot.type = 'scatter'
        subplot.name = '10Hz - 10KHz'
        this.data.push(subplot)
      }


      // let ejeX = []
      // for (let i=0; i < this.results_D11.tlist.length; i++){
      //   ejeX.push(this.results_D11.tlist[i][0])
      // }
      this.data[0].x = this.results_D11.tlist
      //this.data[0].x = ejeX
      this.data[0].y = this.results_D11.SPL_C1media
    }
  }
}
</script>