const state = () => ({
  status: 1, // 1.- Normal / 2.- Loading / 3.- Waiting for response / 4.- Error
  current_interface: 1, // 1.- step 1 // 2.- step 2 // 3.- step 3 // 4.- step 4 // 5.- summary table // 6.- Map select source // 7.- Map select destination // 8.- Display results
  loading_dft: 0, // 0.- Not loading / 1.- Processing / 2.- Fetching from DB
  loading_spl: 0,
  loading_d11: 0,
  loading_espec: 0,
})

const mutations = {
  set_status(state, data){
    state.status = data
  },
  set_current_interface(state, data){
    state.current_interface = data
  },
  set_loading_dft(state, data){
    state.loading_dft = data
  },
  set_loading_spl(state, data){
    state.loading_spl = data
  },
  set_loading_d11(state, data){
    state.loading_d11 = data
  },
  set_loading_espec(state, data){
    state.loading_espec = data
  }
}

const actions = {
  change_status({ commit }, status) {
    commit("set_status", status)
  },
  change_current_interface({ commit }, current_interface) {
    commit("set_current_interface", current_interface)
  },
  change_loading_dft({ commit }, status) {
    commit("set_loading_dft", status)
  },
  change_loading_spl({ commit }, status) {
    commit("set_loading_spl", status)
  },
  change_loading_d11({ commit }, status) {
    commit("set_loading_d11", status)
  },
  change_loading_espec({ commit }, status) {
    commit("set_loading_espec", status)
  }
}

 const getters = {
    status: state => state.status,
    current_interface: state => state.current_interface,
    loading_dft: state => state.loading_dft,
    loading_spl: state => state.loading_spl,
    loading_d11: state => state.loading_d11,
    loading_espec: state => state.loading_espec,
  }

  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }