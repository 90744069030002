<template>
    <div class="modal fade  bd-example-modal-lg" id="history" tabindex="-1" role="dialog" aria-labelledby="historyLabel" aria-hidden="true">
        <div class="modal-dialog " role="document">
            <div class="modal-content">
                <div class="modal-body p-4">
                    <table class="table table-borderless  table-striped w-100">
                        <thead>
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Date</th>
                                <th scope="col">DFT</th>
                                <th scope="col">SPL</th>
                                <th scope="col">D11</th>
                                <th scope="col">Spect.</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr   
                                v-for="(item, index) in job_list"
                                v-bind:key="index"
                            >
                                <td>  
                                    <a @click="loadResults(index)" class="link-1" data-dismiss="modal">
                                        {{ item.name }}
                                    </a>
                                </td>
                                <td>
                                    {{ date(item.creation_date) }}
                                </td>
                                <td>
                                    <span v-if="item.proces_dft">✔️</span>
                                    <span v-else>❌</span>
                                </td>       
                                <td>
                                    <span v-if="item.proces_spl">✔️</span>
                                    <span v-else>❌</span>
                                </td>
                                <td>
                                    <span v-if="item.proces_d11">✔️</span>
                                    <span v-else>❌</span>
                                </td>
                                <td>
                                    <span v-if="item.proces_espec">✔️</span>
                                    <span v-else>❌</span>
                                </td>
                                <td>
                                    <button type="button" @click="deleteJob(item.id)" class="btn btn-danger ">Delete</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="row p-2">
                        <div class="col-sm-12 text-right">
                            <a type="button" class="link-1" data-dismiss="modal">Close</a>
                        </div>
                    </div>
                </div>          
            </div>
        </div>
    </div>
</template>

<script>
// Vuex
import { mapGetters } from "vuex";

// Services
import { postData } from '@/services/api'
import { formatDate } from '@/services/helper'

export default {
  name: 'history',
  components: {
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters({
      job_list: 'dsptool/jobs_list'
    })
  },
  mounted () { 

  },
  created () {
      //this.$store.dispatch('urn/get_job_list')
  },
  methods: {
    getResults: function (job_id, job_type) {
      let data = [job_id, job_type]
      this.$store.dispatch('dsptool/getResults', data)
    },
    loadResults: function(index) {
      this.$store.dispatch('ui/change_status', 1)
      this.$store.dispatch('ui/change_current_interface', 0)
      this.$store.dispatch('dsptool/change_job_id', this.job_list[index].id)
      this.$store.dispatch('dsptool/change_name', this.job_list[index].name)
      this.$store.dispatch('dsptool/change_job_saved', true)

      if(this.job_list[index].proces_dft){
        this.getResults(this.job_list[index].id, 'dft')
        this.$store.dispatch('dsptool/change_proces_dft', true)
      }
      else {
        this.$store.dispatch('dsptool/change_proces_dft', false)
      }

      if(this.job_list[index].proces_spl){
        this.getResults(this.job_list[index].id, 'spl')
        this.$store.dispatch('dsptool/change_proces_spl', true)
      }
      else {
        this.$store.dispatch('dsptool/change_proces_spl', false)
      }

      if(this.job_list[index].proces_d11){
        this.getResults(this.job_list[index].id, 'd11')
        this.$store.dispatch('dsptool/change_proces_d11', true)
      }
      else {
        this.$store.dispatch('dsptool/change_proces_d11', false)
      }
    },
    deleteJob: function (job_id) {
        const formData = new FormData()
        formData.append("job_id", job_id)
        postData("processing/delete/", formData).then(res => {
            if (res.status == 200) {
                this.$noty.success("Successfully removed")
                this.$store.dispatch('dsptool/change_update_jobs_list', true)
            }
            else {
                this.$noty.error("Could not be removed")
            }
        }).catch(error => {
            this.$noty.error("Could not be removed")
        });
    },
    date: function (date) {
        return formatDate(date)
    },
  }
};
</script>

<style lang = "scss" scoped>
.modal-dialog {
  max-width: 850px;
}
</style>
