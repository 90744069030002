<template>
  <div>
    <Plotly :data="data" :layout="layout" :scrollZoom="true" :displaylogo="false" :modeBarButtonsToRemove="['resetScale2d', 'hoverCompareCartesian', 'hoverClosestCartesian']"></Plotly>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { Plotly } from 'vue-plotly'

export default {
  name: 'resultsGraphEspec1',
  components: {
    Plotly
  },
  props: {
    height: Number,
    selected_file: Number
  },
  data () {
    return {
      data: [
        // ----- Gráfico de archivo seleccionado ------
        {
          z: [],
          type: 'heatmap',
          colorscale: 'Portland',
          colorbar: {
            title: 'Sxx',
            //x: .95, 
            //y: .23,
            //len: 0.5
            //thickness: 10
          },
          //hovertemplate: 'Sxx: %{z:.2f}<extra></extra>',
          hoverinfo: 'skip',
          zsmooth: 'best'
        }
      ],
      layout:{
        legend: {
          orientation: 'h',
          y: 1.1
        },
        yaxis: {
          //autorange: 'reversed',
          title: {
            text: 'Freq [Hz]'
          },
          // type: 'log',
          // autorange: true,
          // showexponent: 'all',
          // exponentformat: 'power'
        },
        xaxis: {
          title: {
            text: 'Time [s]'
          },
          // type: 'log',
          // autorange: true,
          // showexponent: 'all',
          // exponentformat: 'power'
        },
        plot_bgcolor:"#FFFFFF",
        paper_bgcolor:"#FFF3",
        dragmode: 'pan',
        modebar: {
          bgcolor: 'rgba(0,0,0,0)',
          color: '#555555',
          activecolor: '#bbbbbb',
          orientation: 'v'
        },
        height: 340,
        margin: {
          t: 10,
          r: 160
        },
        hoverlabel: {
          font: {
            color: 'white'
          }
        }
      },
      y_min: 0,
      y_max: 0
    } 
  },
  computed: {
    ...mapGetters({
      results_espec: 'dsptool/results_espec'
    }),
  },
  created () {
    this.layout.height = this.height
    this.layout.width = this.layout.width - 10
  },
  mounted () {
    this.drawGraph()
  },
  watch: {
    selected_file: function () {
      this.drawGraph()
    },
  },
  methods: {
    drawGraph: function () {
      this.data[0].x = this.results_espec.t[this.selected_file]
      this.data[0].y = this.results_espec.f[this.selected_file]
      this.data[0].z = this.results_espec.Sxx[this.selected_file]
    },
  }
}
</script>