import { postData, getBathymetry } from "@/services/api";
import Vue from "vue";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
import Vuelidate from "vuelidate";

Vue.use(VueToast, Vuelidate);

const state = () => ({
  pos_emitter: {
    x: null,
    y: null,
    z: null,
  },
  pos_receiver: {
    x: null,
    y: null,
    z: null,
  },
  cartesian_or_geographic: "cartesian",
  last_pos_type_modified: "",
  pos_receiver_cartesian: {
    x: 0,
    y: 0,
  },
  f_ini: null,
  f_end: null,
  f_step: 1, // 1= Octavas // 2= 1/3 Octavas
  model: "esferica", // 1= Simple 1 / 2= Simple 2 / 3= Avanced 1 / 4= Advanced 2 / 5= Advanced 3
  bathymetry: null,
  result: {
    transect_1: {
      rR: null,
      zR: null,
      f: null,
      TL: null,
    },
    transect_2: {
      rR: null,
      f: null,
      TL: null,
    },
  },
  selected_f: 0,
  graph_color: {
    min: {
      r: 255,
      g: 211,
      b: 107,
    },
    mid: {
      r: 168,
      g: 14,
      b: 84,
    },
    max: {
      r: 11,
      g: 2,
      b: 65,
    },
  },
  graph_min_TL: 30,
  graph_max_TL: 100,
  form_validator: {
    pos_emitter_x: false,
    pos_emitter_y: false,
    pos_emitter_z: false,
    pos_receiver_x: false,
    pos_receiver_y: false,
    pos_receiver_z: false,
    f_ini: false,
    f_end: false,
  },
  selected_point: {
    x: null,
    y: null,
  },
});

const mutations = {
  set_pos_emitter_x(state, data) {
    state.pos_emitter.x = data;
  },
  set_pos_emitter_y(state, data) {
    state.pos_emitter.y = data;
  },
  set_pos_emitter_z(state, data) {
    state.pos_emitter.z = data;
  },
  set_pos_receiver_x(state, data) {
    state.pos_receiver.x = data;
  },
  set_pos_receiver_y(state, data) {
    state.pos_receiver.y = data;
  },
  set_pos_receiver_z(state, data) {
    state.pos_receiver.z = data;
  },
  set_cartesian_or_geographic(state, data) {
    state.cartesian_or_geographic = data;
  },
  set_pos_receiver_cartesian_x(state, data) {
    state.pos_receiver_cartesian.x = data;
  },
  set_pos_receiver_cartesian_y(state, data) {
    state.pos_receiver_cartesian.y = data;
  },
  //// Indicamos en el state si se ha modificado geographic o cartesian
  set_last_pos_type_modified(state, data) {
    state.last_pos_type_modified = data;
  },
  set_f_ini(state, data) {
    state.f_ini = data;
  },
  set_f_end(state, data) {
    state.f_end = data;
  },
  set_f_step(state, data) {
    state.f_step = data;
  },
  set_model(state, data) {
    state.model = data;
  },
  set_bathymetry(state, data) {
    state.bathymetry = data;
  },
  set_transect_1(state, data) {
    state.result.transect_1.rR = data.rR;
    state.result.transect_1.zR = data.zR;
    state.result.transect_1.f = data.f;
    state.result.transect_1.TL = data.TL;
  },
  set_transect_2(state, data) {
    state.result.transect_2.rR = data.rR;
    state.result.transect_2.f = data.f;
    state.result.transect_2.TL = data.TL;
  },
  set_selected_f(state, data) {
    state.selected_f = data;
  },
  set_graph_color(state, data) {
    state.graph_color = data;
  },
  set_graph_min_TL(state, data) {
    state.graph_min_TL = data;
  },
  set_graph_max_TL(state, data) {
    state.graph_max_TL = data;
  },
  set_validator(state, data) {
    state.form_validator[data[1]] = data[0];
  },
  set_selected_point_x(state, data) {
    state.selected_point.x = data;
  },
  set_selected_point_y(state, data) {
    state.selected_point.y = data;
  },
};

const actions = {
  change_pos_emitter_from_map({ dispatch, commit, getters }, latlng) {
    commit("set_pos_emitter_x", latlng.lng);
    commit("set_pos_emitter_y", latlng.lat);
    let datax = [String(latlng.lng), "pos_emitter_x"];
    dispatch("urn/validate", datax, { root: true });
    let datay = [String(latlng.lat), "pos_emitter_y"];
    dispatch("urn/validate", datay, { root: true });
    if (getters["last_pos_type_modified"] == "cartesian") {
      dispatch("getCoords");
    } else dispatch("getDistances");
  },
  change_pos_emitter_x({ dispatch, commit, getters }, x) {
    commit("set_pos_emitter_x", x);
    let data = [String(x), "pos_emitter_x"];
    dispatch("urn/validate", data, { root: true });
    if (getters["last_pos_type_modified"] == "cartesian") {
      dispatch("getCoords");
    } else dispatch("getDistances");
  },
  change_pos_emitter_y({ dispatch, commit, getters }, y) {
    commit("set_pos_emitter_y", y);
    let data = [String(y), "pos_emitter_y"];
    dispatch("urn/validate", data, { root: true });
    if (getters["last_pos_type_modified"] == "cartesian") {
      dispatch("getCoords");
    } else dispatch("getDistances");
  },
  change_pos_emitter_z({ dispatch, commit }, z) {
    commit("set_pos_emitter_z", z);
    let data = [String(z), "pos_emitter_z"];
    dispatch("urn/validate", data, { root: true });
  },
  change_pos_receiver_x({ dispatch, commit }, x) {
    commit("set_pos_receiver_x", x);
    commit("set_last_pos_type_modified", "geographic");
    let data = [String(x), "pos_receiver_x"];
    dispatch("urn/validate", data, { root: true });
    dispatch("getDistances");
  },
  change_pos_receiver_y({ dispatch, commit }, y) {
    commit("set_pos_receiver_y", y);
    commit("set_last_pos_type_modified", "geographic");
    let data = [String(y), "pos_receiver_y"];
    dispatch("urn/validate", data, { root: true });
    dispatch("getDistances");
  },
  change_distances_from_coords_receiver_x({ commit }, x) {
    commit("set_pos_receiver_cartesian_x", x);
  },
  change_distances_from_coords_receiver_y({ commit }, y) {
    commit("set_pos_receiver_cartesian_y", y);
  },
  change_pos_receiver_z({ dispatch, commit }, z) {
    commit("set_pos_receiver_z", z);
    let data = [String(z), "pos_receiver_z"];
    dispatch("urn/validate", data, { root: true });
  },
  change_pos_receiver_x_from_cartesian({ dispatch, commit }, x) {
    commit("set_pos_receiver_x", x);
    commit("set_last_pos_type_modified", "cartesian");
    let data = [String(x), "pos_receiver_x"];
    dispatch("urn/validate", data, { root: true });
  },
  change_pos_receiver_y_from_cartesian({ dispatch, commit }, y) {
    commit("set_pos_receiver_y", y);
    commit("set_last_pos_type_modified", "cartesian");
    let data = [String(y), "pos_receiver_y"];
    dispatch("urn/validate", data, { root: true });
  },
  change_cartesian_or_geographic({ commit }, cartesian_or_geographic) {
    commit("set_cartesian_or_geographic", cartesian_or_geographic);
  },
  change_pos_receiver_cartesian_x({ dispatch, commit }, x) {
    commit("set_pos_receiver_cartesian_x", x);
    dispatch("getCoords");
  },
  change_pos_receiver_cartesian_y({ dispatch, commit }, y) {
    commit("set_pos_receiver_cartesian_y", y);
    dispatch("getCoords");
  },
  change_f_ini({ dispatch, commit }, f_ini) {
    commit("set_f_ini", f_ini);
    let data = [String(f_ini), "f_ini"];
    dispatch("urn/validate", data, { root: true });
  },
  change_f_end({ dispatch, commit }, f_end) {
    commit("set_f_end", f_end);
    let data = [String(f_end), "f_end"];
    dispatch("urn/validate", data, { root: true });
  },
  change_f_step({ commit }, f_step) {
    commit("set_f_step", f_step);
  },
  change_model({ commit }, model) {
    commit("set_model", model);
  },
  change_bathymetry({ commit }, bathymetry) {
    commit("set_bathymetry", bathymetry);
  },
  change_transect_1({ commit }, transect_1) {
    commit("set_transect_1", transect_1);
  },
  change_transect_2({ commit }, transect_2) {
    commit("set_transect_2", transect_2);
  },
  change_selected_f({ commit }, selected_f) {
    commit("set_selected_f", selected_f);
  },
  change_graph_color({ commit }, graph_color) {
    commit("set_graph_color", graph_color);
  },
  change_graph_min_TL({ commit }, graph_min_TL) {
    commit("set_graph_min_TL", graph_min_TL);
  },
  change_graph_max_TL({ commit }, graph_max_TL) {
    commit("set_graph_max_TL", graph_max_TL);
  },
  change_selected_point_x({ commit }, x) {
    commit("set_selected_point_x", parseInt(x));
  },
  change_selected_point_y({ commit }, y) {
    commit("set_selected_point_y", parseInt(y));
  },

  validate({ commit }, data) {
    let payload = [false, data[1]];
    if (data[0] && data[0].replace(/([^\d]*)(\d*(\.\d{0,2})?)(.*)/, "$2")) {
      payload[0] = true;
    }
    commit("set_validator", payload);
  },

  toast() {
    Vue.$toast.open({
      message: "Algo fue mal, comprueba todos los datos.",
      type: "error",
      position: "top",
      pauseOnHover: true,
      duration: 6000,
    });
  },
  

  // Calculate
  // calculate({ dispatch, commit, getters }) {
  //   // Set 'loading' status
  //   dispatch("ui/change_current_interface", 0, { root: true });
  //   dispatch("ui/change_status", 2, { root: true });
  //   // Get bathymetry from Emodnet API
  //   let url =
  //     "https://rest.emodnet-bathymetry.eu/depth_profile?geom=LINESTRING(" +
  //     getters.pos_emitter.x +
  //     " " +
  //     getters.pos_emitter.y +
  //     "," +
  //     getters.pos_receiver.x +
  //     " " +
  //     getters.pos_receiver.y +
  //     ")";
  //   getBathymetry(url).then((res) => {
  //     let bty = [];
  //     res.data.forEach((position) => {
  //       if (position == null) {
  //         bty.push(0);
  //       } else {
  //         bty.push(position);
  //       }
  //     });
  //     commit("set_bathymetry", bty);

  //     // Calculate model
  //     const formData = new FormData();
  //     for (const key in getters) {
  //       if (key == "pos_emitter") {
  //         formData.append("pos_emitter_x", getters[key].x);
  //         formData.append("pos_emitter_y", getters[key].y);
  //         formData.append("pos_emitter_z", getters[key].z);
  //       } else if (key == "pos_receiver") {
  //         formData.append("pos_receiver_x", getters[key].x);
  //         formData.append("pos_receiver_y", getters[key].y);
  //         formData.append("pos_receiver_z", getters[key].z);
  //       } else if (
  //           key != "transect_1" &&
  //           key != "transect_2" &&
  //           key != "selected_f" &&
  //           key != "graph_color" &&
  //           key != "graph_min_TL" &&
  //           key != "graph_max_TL" &&
  //           key != "form_validator" &&
  //           key != "cartesian_or_geographic" &&
  //           key != "pos_receiver_cartesian" &&
  //           key != "selected_point_x" &&
  //           key != "selected_point_y" &&
  //           key != "last_pos_type_modified"
  //         ) {
  //           formData.append(key, getters[key]);
  //         }
  //     }
  //     url = "noisemodels/calculate/";
  //     postData(url, formData)
  //       .then((res) => {
  //         if (res.status == "200") {
  //           commit("set_transect_1", res.data.transect_1);
  //           commit("set_transect_2", res.data.transect_2);

  //           // Remove 'loading' status and display results
  //           dispatch("ui/change_current_interface", 8, { root: true });
  //           dispatch("ui/change_status", 1, { root: true });
  //         } else {
  //           dispatch("ui/change_current_interface", 5, { root: true });
  //           dispatch("ui/change_status", 1, { root: true });
  //           Vue.$toast.open({
  //             message: "Something went wrong. Process stopped.",
  //             type: "error",
  //             position: "top",
  //             pauseOnHover: true,
  //             duration: 6000,
  //           });
  //         }
  //       })
  //       .catch((err) => console.log(err));
  //   });
  // },
  getCoords({ dispatch, commit, getters }) {
    if (
      getters["pos_emitter"].x &&
      getters["pos_emitter"].y &&
      (getters["pos_receiver_cartesian"].x ||
        getters["pos_receiver_cartesian"].y)
    ) {
      const url = "noisemodels/coords/";
      const formData = new FormData();
      formData.append("pos_emitter_x", getters["pos_emitter"].x);
      formData.append("pos_emitter_y", getters["pos_emitter"].y);
      formData.append("dist_x", getters["pos_receiver_cartesian"].x);
      formData.append("dist_y", getters["pos_receiver_cartesian"].y);
      postData(url, formData).then((res) => {
        dispatch(
          "change_pos_receiver_x_from_cartesian",
          res.data.pos_destination[1]
        );
        dispatch(
          "change_pos_receiver_y_from_cartesian",
          res.data.pos_destination[0]
        );
      });
    }
  },
  getDistances({ dispatch, commit, getters }) {
    if (
      getters["pos_emitter"].x &&
      getters["pos_emitter"].y &&
      getters["pos_receiver"].x &&
      getters["pos_receiver"].y
    ) {
      const url = "noisemodels/distances/";
      const formData = new FormData();
      formData.append("pos_emitter_x", getters["pos_emitter"].x);
      formData.append("pos_emitter_y", getters["pos_emitter"].y);
      formData.append("pos_receiver_x", getters["pos_receiver"].x);
      formData.append("pos_receiver_y", getters["pos_receiver"].y);
      postData(url, formData).then((res) => {
        dispatch(
          "change_distances_from_coords_receiver_x",
          res.data.distance_y.toFixed(0)
        );
        dispatch(
          "change_distances_from_coords_receiver_y",
          res.data.distance_x.toFixed(0)
        );
      });
    }
  },
};

const getters = {
  pos_emitter: (state) => state.pos_emitter,
  pos_receiver: (state) => state.pos_receiver,
  last_pos_type_modified: (state) => state.last_pos_type_modified,
  f_ini: (state) => state.f_ini,
  f_end: (state) => state.f_end,
  f_step: (state) => state.f_step,
  model: (state) => state.model,
  bathymetry: (state) => state.bathymetry,
  transect_1: (state) => state.result.transect_1,
  transect_2: (state) => state.result.transect_2,
  selected_f: (state) => state.selected_f,
  graph_color: (state) => state.graph_color,
  graph_min_TL: (state) => state.graph_min_TL,
  graph_max_TL: (state) => state.graph_max_TL,
  form_validator: (state) => state.form_validator,
  cartesian_or_geographic: (state) => state.cartesian_or_geographic,
  pos_receiver_cartesian: (state) => state.pos_receiver_cartesian,
  selected_point_x: (state) => state.selected_point.x,
  selected_point_y: (state) => state.selected_point.y,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  VueToast,
  Vuelidate,
};
