import { render, staticRenderFns } from "./input4CargarSenales.vue?vue&type=template&id=81f0db1e&scoped=true"
import script from "./input4CargarSenales.vue?vue&type=script&lang=js"
export * from "./input4CargarSenales.vue?vue&type=script&lang=js"
import style0 from "./input4CargarSenales.vue?vue&type=style&index=0&id=81f0db1e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "81f0db1e",
  null
  
)

export default component.exports