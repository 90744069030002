<template>
  <div class="container-fluid p-0 home-bg">
    <div class="button-new-job" v-on:click="newJob" v-if="current_interface !=10">New processing</div>
    <div class="button-save-job" v-on:click="saveJob" v-if="current_interface == 8 && !job_saved ">Save processing</div>
    <div id="map" class="map-container" v-on:click="getCoords"></div>
    <div class="pt-2 row h-100 interface-overlay">
      <div class="col-sm-2"></div>
      <div v-if="current_interface != 8" class="col-sm-8 my-auto">
        <input1bienvenido v-if="current_interface == 1"></input1bienvenido>
        <input2PuntosMuestreo v-if="current_interface == 2"></input2PuntosMuestreo>
        <input3EquiposMuestreo v-if="current_interface == 3"></input3EquiposMuestreo>
        <input4CargarSenales v-if="current_interface == 4"></input4CargarSenales>
        <input5PrevData v-if="current_interface == 5"></input5PrevData>
        <input6Procesado v-if="current_interface == 6"></input6Procesado>
        <input7ProcesadoResumen v-if="current_interface == 7"></input7ProcesadoResumen>
        <inputMap v-if="current_interface == 10"></inputMap>
      </div>
      <div v-else class="col-sm-8">
        <results-container v-if="current_interface == 8"></results-container>
      </div>
      <div class="col-sm-2"></div>
    </div>
  </div>
</template>

<script>
// Leaflet
import "leaflet/dist/leaflet.css"
import L from "leaflet"

// Vuex
import { mapGetters } from "vuex";

// Components and services
import { getData, postData } from '@/services/api'
import { formatDate } from '@/services/helper'
import input1bienvenido from '@/components/process-steps/input1bienvenido.vue'
import input2PuntosMuestreo from '@/components/process-steps/input2PuntosMuestreo.vue'
import input3EquiposMuestreo from '@/components/process-steps/input3EquiposMuestreo.vue'
import input4CargarSenales from '@/components/process-steps/input4CargarSenales.vue'
import input5PrevData from '@/components/process-steps/input5PrevData.vue'
import input6Procesado from '@/components/process-steps/input6Procesado.vue'
import inputMap from '@/components/process-steps/inputMap.vue'
import input7ProcesadoResumen from '@/components/process-steps/input7ProcesadoResumen.vue'
import resultsContainer from '@/components/process-steps/resultsContainer.vue'

export default {
  name: 'DspTool',
  components: {
    input1bienvenido,
    input2PuntosMuestreo,
    input3EquiposMuestreo,
    input4CargarSenales,
    input5PrevData,
    input6Procesado,
    inputMap,
    input7ProcesadoResumen,
    resultsContainer,
  },
  computed: {
    ...mapGetters({
      user_id: 'user/user_id',
      status: 'ui/status', 
      current_interface: 'ui/current_interface',
      jobs_list: 'dsptool/jobs_list',
      update_jobs_list: 'dsptool/update_jobs_list',

      job_id: 'dsptool/job_id',
      job_saved: 'dsptool/job_saved',
      name:'dsptool/name',
      longitude: 'dsptool/longitude',
      latitude: 'dsptool/latitude',
      depth: 'dsptool/depth',
      equip_med: 'dsptool/equip_med',
      fileNames: 'dsptool/fileNames',
      sound_crop_start: 'dsptool/sound_crop_start',
      sound_crop_end: 'dsptool/sound_crop_end',
      proces_dft: 'dsptool/proces_dft',
      proces_spl: 'dsptool/proces_spl',
      // proces_spl_data: 'dsptool/proces_spl_data',
      proces_spl_data_freq_start: 'dsptool/proces_spl_data_freq_start',
      proces_spl_data_freq_end: 'dsptool/proces_spl_data_freq_end',
      proces_spl_data_filtro: 'dsptool/proces_spl_data_filtro',
      proces_spl_data_orden: 'dsptool/proces_spl_data_orden',
      proces_spl_data_paso: 'dsptool/proces_spl_data_paso',
      proces_d11: 'dsptool/proces_d11',
      // set_proces_d11_data: 'dsptool/proces_d11_data',
      proces_d11_data_filtro: 'dsptool/proces_d11_data_filtro',
      proces_d11_data_orden: 'dsptool/proces_d11_data_orden',
      proces_espec: 'dsptool/proces_espec',

    })
  },
  data() {
    return {
      map: null,
      // Marker select point
      icon: {},
      icon_marker: null,

      // Marker jobs history
      icon_jobs: {},
      job_markers: [],

      // Web Socket
      socket: null,

      // Control var to display first input window
      start_new_job: false
    }
  },
  created () {
    let globalThis = this
    const server_path = process.env.VUE_APP_API_WS_S + '://' + process.env.VUE_APP_API_HOST + ':' + process.env.VUE_APP_API_PORT + '/ws/updates/'
    this.socket = new WebSocket(server_path)
    this.socket.addEventListener('open', () => {
      console.log('Conectado');
    })
    this.socket.onmessage = function (event) {
      const data = JSON.parse(event.data)
      if(data.user_id == globalThis.user_id) {
        globalThis.getResults(data.job_id, data.job_type)
        console.log(data)
      }      
    }
    this.$store.dispatch('ui/change_current_interface', 0)
    this.getJobHistory()
    this.deleteJobs()
  },
  mounted () {
    this.setBaseMap()
    this.loadIcons()
  },
  watch: {
    'longitude': function () {
      this.updateMarker()
    },
    'latitude': function () {
      this.updateMarker()
    },
    'update_jobs_list': function () {
      if(this.update_jobs_list) this.getJobHistory()
    },
  },
  methods: {
    setBaseMap: function () {
      this.map = L.map("map", {zoomControl: false}).setView([38.959, 12.623], 6);
      L.tileLayer(
        "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
        {
          attribution:
            '&copy; <a href="https://www.ctninnova.com/en/">Centro Tecnológico Naval y del Mar</a>',
        }
      ).addTo(this.map)
      L.control.scale({imperial: false}).addTo(this.map)
    },
    getCoords: function (e) {
      if(this.current_interface == 10){
        let latlng = this.map.mouseEventToLatLng(e);
        this.$store.dispatch('dsptool/change_longitude', latlng.lng)
        this.$store.dispatch('dsptool/change_latitude', latlng.lat)
      }
    },
    loadIcons: function () {
      let image_marker = require('@/assets/icons/icon_emitter.gif')
      this.icon_marker = L.icon({
        iconUrl: image_marker,
        iconSize:     [100, 100], // size of the icon
        iconAnchor:   [50, 50], // point of the icon which will correspond to marker's location
        popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
      })

      let image_marker_jobs = require('@/assets/icons/marker_jobs.svg')
      this.icon_jobs = L.icon({
        iconUrl: image_marker_jobs,
        iconSize:     [30, 30], // size of the icon
        iconAnchor:   [15, 15], // point of the icon which will correspond to marker's location
        popupAnchor:  [0, -25] // point from which the popup should open relative to the iconAnchor
      })
    },
    newJob: function () {
      this.start_new_job = true
      this.$store.dispatch('dsptool/reset')   
      this.$store.dispatch('dsptool/change_update_jobs_list', true)
      this.$store.dispatch('ui/change_current_interface', 1)
    },
    updateMarker: function () {
      if(this.marker){
        this.map.removeLayer(this.marker)
      }

      if (this.longitude && this.latitude){
          this.marker = L.marker([this.latitude, this.longitude], {icon: this.icon_marker})
          this.marker.addTo(this.map)
      }
    },
    saveJob: function () {
      let formData = new FormData()
      formData.append('job_id', this.job_id)
      formData.append('user_id', this.user_id)
      formData.append('name', this.name)
      formData.append('longitude', this.longitude)
      formData.append('latitude', this.latitude)
      formData.append('depth', this.depth)
      formData.append('proces_dft', this.proces_dft)
      formData.append('proces_spl', this.proces_spl)
      formData.append('proces_d11', this.proces_d11)
      formData.append('proces_espec', this.proces_espec)
      postData('processing/store/', formData)
      .then((res) => {
        if (res.status == "200") {
          this.$store.dispatch('dsptool/change_job_saved', true)
          this.$store.dispatch('dsptool/change_update_jobs_list', true)
          this.$noty.success("Results saved")
        } 
        else {
          this.$noty.error("Error while saving")
        }
      }).catch(error => {
        this.$noty.error("Error while saving")
      });   
    },
    getResults: function (job_id, job_type) {
      if(job_type == "error_max_freq_spl") {
        this.$store.dispatch('ui/change_status', 1)
        this.$store.dispatch('ui/change_current_interface', 6)
        this.$noty.error("The upper limit associated with the final frequency selected for SPL exceeds fs/2.")
      }
      else if(job_type == "error_max_freq_dft") {
        this.$store.dispatch('ui/change_status', 1)
        this.$store.dispatch('ui/change_current_interface', 6)
        this.$noty.error("The upper limit associated with the final frequency selected for SPL exceeds fs/2.")
      }
      else {
        let data = [job_id, job_type]
        this.$store.dispatch('dsptool/getResults', data)
      }   
    },
    getJobHistory: function () {
      const formData = new FormData();
      formData.append("user_id", this.user_id)
      postData('processing/list/',formData)
      .then((res) => {
        console.log(res.data.Jobs)
        this.$store.dispatch('dsptool/change_jobs_list', res.data.Jobs)

        if(res.data.Jobs.length == 0 && !this.start_new_job){
          this.$store.dispatch('ui/change_current_interface', 1)
        }
        else {
          this.setJobMarkers()
        }
        this.start_new_job = false
        this.$store.dispatch('dsptool/change_update_jobs_list', false)

      })
    },
    setJobMarkers: function () {
      this.job_markers.forEach(job => {
        this.map.removeLayer(job)
      })
      this.job_markers = []
      for(let i = 0; i < this.jobs_list.length; i++){
        if(this.jobs_list[i].longitude && this.jobs_list[i].latitude){
          let markerOptions = {
            title: this.jobs_list[i].name,
            clickable: true,
            draggable: false,
            icon: this.icon_jobs
          }
          let popupContent = '<span style="font-size: 1rem; color: #1780e2;">' + this.jobs_list[i].name + "</span><br /><br />"
          popupContent += "<b>Date: </b>" + formatDate(this.jobs_list[i].creation_date) + "<br />"
          popupContent += "<b>Lat: </b>" + this.jobs_list[i].latitude.toFixed(2) + "<br />"
          popupContent += "<b>Lon: </b>" + this.jobs_list[i].longitude.toFixed(2) + "<br />"
          popupContent += "<b>Depth: </b>" + this.jobs_list[i].depth + "m<br /><br />"
          popupContent += "<b>Processes performed</b><br />"
          if(this.jobs_list[i].proces_dft) popupContent += "✔️ DFT<br />"
          if(this.jobs_list[i].proces_spl) popupContent += "✔️ SPL<br />"
          if(this.jobs_list[i].proces_d11) popupContent += "✔️ D11<br />"
          if(this.jobs_list[i].proces_espec) popupContent += "✔️ Spectrogram<br />"
          popupContent += "<br />"
          popupContent += '<button class="load-button" style="background-color: white; border: 1px solid #bbbbbb; border-radius: 10px; padding: 10px; width: 100%; box-shadow: 1px 1px 2px 2px rgba(0,0,0,0.05); cursor: pointer;">Load</button>'

          this.job_markers[i] = new L.Marker([this.jobs_list[i].latitude, this.jobs_list[i].longitude], markerOptions)
          this.job_markers[i].addTo(this.map).bindPopup(popupContent).on("popupopen", (a) => {
              var popUp = a.target.getPopup()
              popUp.getElement()
              .querySelector(".load-button")
              .addEventListener("click", e => {
                this.loadResults(i);
              });
          })
        }
        
      }
    },
    loadResults: function(index) {
      this.map.closePopup()
      this.$store.dispatch('ui/change_status', 1)
      this.$store.dispatch('ui/change_current_interface', 0)
      this.$store.dispatch('dsptool/change_job_id', this.jobs_list[index].id)
      this.$store.dispatch('dsptool/change_name', this.jobs_list[index].name)
      this.$store.dispatch('dsptool/change_job_saved', true)

      if(this.jobs_list[index].proces_dft){
        this.getResults(this.jobs_list[index].id, 'dft')
        this.$store.dispatch('dsptool/change_proces_dft', true)
      }
      else {
        this.$store.dispatch('dsptool/change_proces_dft', false)
      }

      if(this.jobs_list[index].proces_spl){
        this.getResults(this.jobs_list[index].id, 'spl')
        this.$store.dispatch('dsptool/change_proces_spl', true)
      }
      else {
        this.$store.dispatch('dsptool/change_proces_spl', false)
      }

      if(this.jobs_list[index].proces_d11){
        this.getResults(this.jobs_list[index].id, 'd11')
        this.$store.dispatch('dsptool/change_proces_d11', true)
      }
      else {
        this.$store.dispatch('dsptool/change_proces_d11', false)
      }

      if(this.jobs_list[index].proces_espec){
        this.getResults(this.jobs_list[index].id, 'espec')
        this.$store.dispatch('dsptool/change_proces_espec', true)
      }
      else {
        this.$store.dispatch('dsptool/change_proces_espec', false)
      }
    },
    deleteJobs: function () {
      const formData = new FormData()
      postData("processing/delete/", formData)
    }
  }
}
</script>
<style scoped>
  .popup-title {
    font-size: 1.2rem;
    color: blue;
  }
</style>