//import LZMA from 'lzma';
/* <script src="../src/lzma.js"></script> */
//<script src="../src/lzma_worker.js"></script> 

export function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear(),
        hour = d.getHours(),
        minutes = d.getMinutes();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
    if (hour < 10) 
        hour = '0' + hour;
    if (minutes < 10)
        minutes = '0' + minutes;      

    let formatedDate = [day, month, year].join('-');
    let time = [hour, minutes].join(':');

    return [formatedDate, time].join(' ');
}

// export function decompressData(compressedData) {
//     return new Promise((resolve, reject) => {
//       LZMA.decompress(compressedData, (result, error) => {
//         if (result) {
//           resolve(result);
//         } else {
//           reject(error);
//         }
//       });
//     });
//   }
export function compareFileSizes(files) {
    // Get the size of the first file
    var firstSize = files[0].size;
    // Iterate over the remaining files and compare their sizes
    for (var i = 1; i < files.length; i++) {
      if (files[i].size !== firstSize) {
        // Sizes are not equal
        return false;
      }
    }
  
    // All sizes are equal
    return true;
  }