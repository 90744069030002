<template>
    <div class="loader-container">
        <div class="loader" v-bind:class="{ 'loading': status==2 }">
          <div class="loader-text" v-bind:class="{ 'spinner-loading': status==2 }">
              <span v-if="num_files_uploaded < signal_nums">Uploading files...</span>
              <span v-else>Processing...</span>
              <div class="loader-sub-text">This process may take several minutes</div>
          </div>
          <div class="lds-ellipsis" v-bind:class="{ 'spinner-loading': status==2 }"><div></div><div></div><div></div><div></div></div>

          <div v-if="num_files_uploaded < signal_nums">
            <progress id="upload-progress-bar" :value="num_files_uploaded" :max="signal_nums"></progress>
            <br>
            <div v-if="num_files_uploaded == ''"><span>0 / {{ signal_nums }} files uploaded</span></div>
            <div v-else><span>{{ num_files_uploaded }} / {{ signal_nums }} files uploaded</span></div>
          </div>
        </div>
        <div class="loader-wall" v-bind:class="{ 'loader-wall-loading': status==2 }"></div>
    </div>
</template>
<script>
import { mapGetters } from "vuex"; 
export default {
    name: 'loader',
    computed: {
    ...mapGetters({
      status: 'ui/status',
      signal_nums: 'dsptool/signal_nums',
      num_files_uploaded: 'dsptool/num_files_uploaded'
    }),
  },
}
</script>