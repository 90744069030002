<template>
  <div class="navigation-container">
      <span v-on:click="navigate(1)" class="circle" :class="{ completed: current_interface >0 , current: current_interface == 1 }"></span>
      <span class="line" :class="{ completed: current_interface >1 }"></span>
      <span v-on:click="navigate(2)" class="circle" :class="{ completed: current_interface >1 , current: current_interface == 2 }"></span>
      <span class="line" :class="{ completed: current_interface >2 }"></span>
      <span v-on:click="navigate(3)" class="circle" :class="{ completed: current_interface >2 , current: current_interface == 3 }"></span>
      <span class="line" :class="{ completed: current_interface >3 }"></span>
      <span v-on:click="navigate(4)" class="circle" :class="{ completed: current_interface >3 , current: current_interface == 4 }"></span>
      <span class="line" :class="{ completed: current_interface >4 }"></span>
      <span v-on:click="navigate(5)" class="circle" :class="{ completed: current_interface >4 , current: current_interface == 5 }"></span>
      <span class="line" :class="{ completed: current_interface >5 }"></span>
      <span v-on:click="navigate(6)" class="circle" :class="{ completed: current_interface >5 , current: current_interface == 6 }"></span>
      <span class="line" :class="{ completed: current_interface >6 }"></span>
      <span v-on:click="navigate(7)" class="circle" :class="{ completed: current_interface >6 , current: current_interface == 7 }"></span>
  </div>
</template>

<script>
// Vuex
import { mapGetters } from "vuex";

export default {
  name: 'navigation',
  computed: {
    ...mapGetters({
      status: 'ui/status', 
      current_interface: 'ui/current_interface'
    })
  },
  methods: {
    navigate: function (target) {
      this.$store.dispatch('ui/change_current_interface', target)
    }
  }
}
</script>