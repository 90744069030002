<template>
  <div>
    <!--<Plotly :data="data" :layout="layout" :display-mode-bar="true" :scrollZoom="true" :displaylogo="false"></Plotly>-->
    <Plotly :data="data" :layout="layout" :scrollZoom="true" :displaylogo="false" id="plotly2"></Plotly>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { Plotly } from 'vue-plotly'

export default {
  name: 'graph1wave',
  components: {
    Plotly
  },
  props: {
    y: '',
    t: '',
    crop_start: '',
    crop_end: ''
  },
  data () {
    return {
      data: [
        // ----- Gráfico de onda ------
        {
          x: [],
          y: [],
          name: 'wav',
          mode: 'lines',
          hoverinfo: 'skip',
          line: {
            color: 'rgba(55, 210, 242, 0.7)',
            width: 2
          },
        },
        // ----- Crop start ------
        {
          x: [],
          y: [],
          name: 'Initial cut',
          mode: 'lines',
          hoverinfo: 'skip',
          line: {
            color: 'white',
            width: 1.5,
            dash: 'dot'
          },
        },
        // ----- Crop end ------
        {
          x: [],
          y: [],
          name: 'End cut',
          mode: 'lines',
          hoverinfo: 'skip',
          line: {
            color: 'orange',
            width: 1.5,
            dash: 'dot'
          },
        }
      ],
      layout:{
        yaxis: {
          autorange: 'reversed',
          title: {
            text: 'Amplitude [u.a]'
          }
        },
        xaxis: {
          title: {
            text: 'Time [s]'
          }
        },
        plot_bgcolor:"#052030",
        paper_bgcolor:"#FFF3",
        dragmode: 'pan',
        modebar: {
          bgcolor: 'rgba(0,0,0,0)',
          color: '#555555',
          activecolor: '#bbbbbb',
          orientation: 'v'
        },
        height: 340,
        margin: {
          t: 10,
          r: 160
        },
        hoverlabel: {
          font: {
            color: 'white'
          }
        }
      },
      y_min: 0,
      y_max: 0
    } 
  },
  computed: {
    ...mapGetters({
      // status: 'ui/status', 
      // transect_2: 'urn/transect_2',
      // bathymetry: 'urn/bathymetry',
      // pos_emitter: 'urn/pos_emitter',
      // pos_receiver: 'urn/pos_receiver',
      // selected_f: 'urn/selected_f',
    }),
    // selected_point_x: {
    //   set (val) { this.$store.dispatch('urn/change_selected_point_x', val); },
    //   get () { return this.$store.state.urn.selected_point.x }
    // },
    // selected_point_y: {
    //   set (val) { this.$store.dispatch('urn/change_selected_point_y', val); },
    //   get () { return this.$store.state.urn.selected_point.y }
    // },
  },
  mounted () {
    this.calculateMinMaxY()

    // Draw Graph
    this.drawGraph()
    this.drawCrops()

    // Create listener to handle click event (To display freqs graph)
    // let globalThis = this
    // document.getElementById('plotly2').on('plotly_click', function(data){
    //   globalThis.setSelectedPoint(data)
    // })
  },
  watch: {
    crop_start: function () {
      this.drawCrops()
    },
    crop_end: function () {
      this.drawCrops()
    },
  },
  methods: {
    drawGraph: function () {
      this.data[0].x = this.t
      // for(let i = 10; i < this.transect_2.rR.length; i++){
      //   this.data[0].x.push(this.transect_2.rR[i])
      // }
      this.data[0].y = this.y
      // for(let i = 10; i < this.transect_2.rR.length; i++){
      //   this.data[0].y.push(this.transect_2.TL[i][this.selected_f])
      // }
    },
    // setSelectedPoint: function (data) {
    //   this.selected_point_x = this.transect_2.rR.indexOf(data.points[0].x)
    // },
    drawCrops: function () {
    
      this.data[1].x = [this.crop_start, this.crop_start]
      this.data[1].y = [this.y_min, this.y_max]

      this.data[2].x = [this.t[this.t.length-1] - this.crop_end, this.t[this.t.length-1] - this.crop_end]
      this.data[2].y = [this.y_min, this.y_max]
    },
    calculateMinMaxY: function () {
      this.y_min = Math.min(...this.y)
      this.y_max = Math.max(...this.y)
    }
  }
}
</script>