<template>
  <div>
    <div class="card-header-1">
      <div class="card-header-title">SAMPLING EQUIPMENT</div>
      <div class="close-inputs" @click="closeInputs"></div>
    </div>
    <div class="card-body-1 fixed-height">
      <div class="row h-100">
        <div class="col-sm-1 my-auto text-center input-arrow" v-on:click="navigate(2)"><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
  <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
</svg> </div>
        <div class="col-sm-10">
          <div class="row text-center">
            <div class="col-sm-12 ">
              <navigation></navigation>
            </div>
          </div>
          <div class="row form-group equipo-group d-flex justify-content-center">
            <div class="col-sm-4 my-auto">
              <div class="input-label">Select measuring equipment</div>
              <select class="select-input" v-model="equipo_selected" :disabled="add_equipo">
                  <option v-for="(equipment, index) in equipo_list" v-bind:key="index" :value="equipment.id">{{equipment.name}}</option>
              </select>
              <div class="d-flex justify-content-center">
              <button v-if="!add_equipo" @click="set_equipo_manual" class="button-standard" style="margin-top: 2rem;">Add Equipment Manually</button>
              <button v-else @click="set_equipo_manual" class="button-standard d-flex justify-content-center" style="margin-top: 2rem;">Select equipment from a list</button>
              </div>
            </div> 
            <div class="col-sm-4 my-auto">
              <img :src="require(`@/assets/images/equipos/${equipo_selected}.jpg`)" alt="Imagen de equipo" class="rounded float-left imagen-equipo"/>
            </div>    
          </div>

            <div v-if="add_equipo" class="equipo-manual">
              <div class="input-label d-flex justify-content-center"><b>Enter the equipment data</b></div>
              <form>
                <div class="row">
                  <div class="col">
                    <div class="input-label">Name</div>
                    <input type="text" class="general-input form-control" placeholder="Name" v-model="equipo_manual_name">
                  </div>
                  <div class="col">
                    <div class="input-label">Sensitivity</div>
                    <input type="text" class="general-input form-control" placeholder="dB re 1V/uPa​" v-model="equipo_manual_sensivity">
                  </div>
                  <div class="col">
                    <div class="input-label">Fc</div>
                    <input type="text" class="general-input form-control" placeholder="u.a." v-model="equipo_manual_fc">
                  </div>
                  <div class="col">
                    <div class="input-label">Gain</div>
                    <input type="text" class="general-input form-control" placeholder="dB" v-model="equipo_manual_gp">
                  </div>
                  <div class="col">
                    <div class="input-label">Medium</div>
                    <select class="select-input" v-model="equipo_manual_pref">
                      <option value="agua">Water</option>
                      <option value="aire">Air</option>
                    </select>
                  </div>
                </div>
              </form>
            </div>       
        </div>
        <div class="col-sm-1 my-auto text-center input-arrow" v-on:click="navigate(4)"><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
  <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
</svg></div>
      </div>
    </div>
  </div>
</template>

<script>
// Vuex
import { mapGetters } from "vuex";

// Archivo para comunicarse con el backend
import {getData} from "@/services/api"

// Components and services
import navigation from '@/components/process-steps/navigation.vue'
import {required, decimal} from 'vuelidate/lib/validators'

export default {
  name: 'input13Freq',
  components: {
    navigation
  },
  computed: {
    ...mapGetters({
      status: 'ui/status', 
      current_interface: 'ui/current_interface',
    }),
    equipo_list: {
      set (data) { this.$store.dispatch('dsptool/change_equipo_list', data); },
      get () { return this.$store.state.dsptool.formInputs.equipo_list }
    },
    equipo_selected: {
      set (data) { this.$store.dispatch('dsptool/change_equipo_selected', data); },
      get () { return this.$store.state.dsptool.formInputs.equipo_selected }
    },
    // EQUIPO MANUAL
    equipo_manual_name: {
      set (data) { this.$store.dispatch('dsptool/change_equipo_manual_name', data); },
      get () { return this.$store.state.dsptool.formInputs.equipo_manual.name }
    },
    equipo_manual_sensivity: {
      set (data) { this.$store.dispatch('dsptool/change_equipo_manual_sensivity', data); },
      get () { return this.$store.state.dsptool.formInputs.equipo_manual.sensivity }
    },
    equipo_manual_fc: {
      set (data) { this.$store.dispatch('dsptool/change_equipo_manual_fc', data); },
      get () { return this.$store.state.dsptool.formInputs.equipo_manual.fc }
    },
    equipo_manual_gp: {
      set (data) { this.$store.dispatch('dsptool/change_equipo_manual_gp', data); },
      get () { return this.$store.state.dsptool.formInputs.equipo_manual.gp }
    },
    equipo_manual_pref: {
      set (data) { this.$store.dispatch('dsptool/change_equipo_manual_pref', data); },
      get () { return this.$store.state.dsptool.formInputs.equipo_manual.pref }
    },
  },
  data() {
    return {
        add_equipo: false,
    }
  },
  created () {
    this.getEquipos()
  },
  mounted () {

  },
  methods: {
    navigate: function (target) {
      this.$store.dispatch('ui/change_current_interface', target)
    },
    closeInputs: function () {
      this.$store.dispatch('ui/change_current_interface', 0)
    },
    getEquipos: function () {
      let url = "processing/list-equipments/"
      getData(url).then(res => {
        this.equipo_list = res.data.Equipments
      })
    },
    set_equipo_manual: function () {
      if(!this.add_equipo)  {
        this.add_equipo = true
        this.equipo_selected = 0
      }
      else {
        this.add_equipo = false
        this.equipo_selected = 1
      }
    }
  }
}
</script>

<style scoped>
.equipo-group{
  margin-top: 2rem;
}
.equipo-manual{
  background:#515151;
  padding: 5px 10px 10px 10px;
  border-radius: 5px;
}
</style>