<template>
  <div>
    <div class="card-header-1">
      <div class="card-header-title">DATA PREVIEW</div>
      <div class="close-inputs" @click="closeInputs"></div>
    </div>
    <div class="card-body-1 fixed-height">
      <div class="row h-100">
        <div class="col-sm-1 my-auto text-center input-arrow" v-on:click="navigate(4)">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
            <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
          </svg>
        </div>
        <div class="col-sm-10">
          <div class="row text-center">
            <div class="col-sm-12 ">
              <navigation></navigation>
            </div>
          </div>

          <div class="row" style="margin-top: 1rem;">
            <div>
              <div class="col-sm-4">
                  <div class="list-group">
                    <div v-for="(fileName, index) in fileNames" v-bind:key="index" class="list-group-item list-group-item-action" v-bind:class="{ 'active': index == active_item }" @click="changeActiveItem(index)" style="cursor: pointer;"> {{fileName}}</div>
                  </div>
              </div>
            </div>
            <div class="col-sm-8 my-auto">
              <div v-if="this.fileNames.length < 1"><p style="text-align:center;">There are no files to display data</p></div>
              <div v-if="fileTooBig"><p style="text-align:center;">Unable to get preview for files of this size</p></div>
              <graph1wave v-if="showGraph" :y="result_y" :t="result_t" :crop_start="sound_crop_start" :crop_end="sound_crop_end" ></graph1wave>
              <div v-if="!showGraph && this.fileNames.length > 1 && !fileTooBig">
                <div style="text-align:center;"><p>Loading data</p></div>
                <div class="lds-ellipsis spinner-loading center"><div></div><div></div><div></div><div></div></div>
              </div>
              <div v-if="this.fileNames.length > 0" class="row">
                <div class="col">
                  <div class="input-label" >Initial cut</div>
                  <input class="general-input form-control" placeholder="Segundos" type="text" v-model="sound_crop_start">
                </div>
                <div class="col">
                  <div class="input-label" >End Cut</div>
                  <input class="general-input form-control" placeholder="Segundos" type="text" v-model="sound_crop_end">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-1 my-auto text-center input-arrow" v-on:click="navigate(6)"><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
  <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
</svg></div>
      </div>
    </div>
  </div>
</template>

<script>
// Vuex
import { mapGetters } from "vuex";

// Components and services
import navigation from '@/components/process-steps/navigation.vue'
import graph1wave from '@/components/process-steps/graph1wave.vue'
import { postData } from  '@/services/api'

export default {
  name: 'input5PrevData',
  components: {
    navigation,
    graph1wave
  },
  computed: {
    ...mapGetters({
      status: 'ui/status', 
      current_interface: 'ui/current_interface',
      job_id: 'dsptool/job_id',
      fileNames: 'dsptool/fileNames',
      signal_props: 'dsptool/signal_props'
    }),
    sound_crop_start: {
      set (data) { this.$store.dispatch('dsptool/change_sound_crop_start', data); },
      get () { return this.$store.state.dsptool.formInputs.sound_crop_start }
    },
    sound_crop_end: {
      set (data) { this.$store.dispatch('dsptool/change_sound_crop_end', data); },
      get () { return this.$store.state.dsptool.formInputs.sound_crop_end }
    },
  },
  data() {
    return {
      error_msg: false,
      active_item: 0,
      result_y: [],
      result_t: [],
      showGraph: false,
      fileTooBig: false
    }
  },
  mounted () {
    if(this.fileNames.length > 0) {
      this.changeActiveItem(0)
    }
  },
  methods: {
    navigate: function (target) {
      this.$store.dispatch('ui/change_current_interface', target)
    },
    closeInputs: function () {
      this.$store.dispatch('ui/change_current_interface', 0)
    },
    process : function () {
      console.log("Process Llamado")
    },
    changeActiveItem: function (index) {
      this.showGraph = false
      this.active_item = index

      if(this.signal_props.size > 367001600) {
        this.fileTooBig = true
      }
      else {
        let formData = new FormData()
        formData.append('job_id', this.job_id)
        formData.append('file_name', this.fileNames[index])
        postData('processing/getwaveform/', formData)
        .then(res => {
          this.result_y = res.data.y
          this.result_t = res.data.t
          this.showGraph = true
        })
      }
      
    }
  }
}
</script>
<style lang="scss" scoped>
.overview-row-title {
  font-weight: 600;
  color: #17327e
}
.overview-param-title {
  font-size: 1rem;
  color: black;
  font-weight: 600;
}

.list-group{
    height: 425px;
    width: 250px;
    /* margin-top: 3rem; */
    overflow:scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    background: #999;
}
.list-group-item {
  background-color: #b9b9b9;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>