<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light">
      <div class="container-fluid">
        <img :src="require('@/assets/icons/logo.svg')" alt="logo DSP Tool" style="height: 25px; width: auto; margin-right: 12px;" />
        DSPTool
        <!-- Right side of Navbar -->
        <div class="collapse navbar-collapse" id="navbarToggler">
          <ul class="nav navbar-nav navbar-right ml-auto ">
            <!-- <li class="nav-item dropdown mr-5 nav-inicio">
              <router-link class="link-1" tag="a" to="/" exact>
                  Portada
              </router-link>
            </li> -->
            <li class="nav-item dropdown mr-5 nav-inicio">
              <a v-if="isLoggedIn" id="navbarDropdown" class=" dropdown-toggle link-1" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <!-- <img :src="require('@/assets/icons/icon-user.svg')" alt="Profile picture" class="profile-picture"/> -->
                <p v-if="fullname != ''" class="d-inline-block nav-user m-0 link-1">{{fullname}}</p>
                <p v-else class="d-inline-block nav-user m-0 link-1">{{username}}</p>
              </a>
              <!-- <router-link  v-else-if="this.$route.name != 'Login'" tag="a" to="/login" exact> -->
                <!-- <img :src="require('@/assets/icons/icon-user.svg')" alt="Profile picture" class="profile-picture"/> -->
                <!-- <a class="d-inline-block nav-user">Log in</a> -->
              <!-- </router-link>   -->
              <!-- User options pop up -->
              <div class="dropdown-menu dropdown-menu-right dropdown-user" aria-labelledby="navbarDropdown">
                <div class="popup-body">
                  <div class="dropdown-item modal-call pointer"  data-toggle="modal" data-target="#history" > &nbsp;Processing historical</div>
                  <div class="line-separator"><hr /></div>
                  <!-- <div v-if="is_admin == true || is_admin == 'true'" class="dropdown-item modal-call pointer" data-toggle="modal" data-target="#management"> Users Management</div> -->
                  <!-- <div v-if="is_admin == true || is_admin == 'true'" class="line-separator"><hr /></div> -->
                  <!-- <div class="dropdown-item modal-call pointer"  data-toggle="modal" data-target="#userProfile" > &nbsp;User profile</div> -->
                  <!-- <div class="line-separator"><hr /></div> -->
                  <div>
                    <div @click="logout" class="dropdown-item pointer">
                      &nbsp;Logout
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="navbar-underline"></div>
    </nav>
    <history></history>
  </div>
</template>
<script>

import { mapGetters } from 'vuex'
import history from '@/components/user/history.vue'

export default {
  name: "dsptHeader",
  components: {
    history
  },
  data() {
    return {
    };
  },
  computed: { 
    ...mapGetters("user",{
      isLoggedIn: 'isLoggedIn',
      username: 'username',
      fullname: 'fullname',
      is_admin: 'is_admin'
    }) 
  },
  methods:{
    logout: function () {
      this.$store.dispatch('user/logout')
      .then(resp => { console.log(resp)})
      .catch(err => err)
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang= "scss" scoped>

.nav-inicio {
    align-content: center;
    display: grid
}

.profile-picture{
    width: 25px;
    padding-right: 5px;
}

.dropdown-user {
  z-index: 10000;
}



</style>
