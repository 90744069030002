<template>
  <div>
    <div class="card-header-1">
      <div class="card-header-title">SAMPLING POINTS</div>
      <div class="close-inputs" @click="closeInputs"></div>
    </div>
    <div class="card-body-1 fixed-height">
      <div class="row h-100">
        <div class="col-sm-1 my-auto text-center input-arrow" v-on:click="navigate(1)"><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
  <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
</svg></div>
        <div class="col-sm-10">
          <div class="row text-center">
            <div class="col-sm-12 ">
              <navigation></navigation>
            </div>
          </div>
          <div class="center-content">
          <div class="row h-25" style="margin-bottom: 30px; margin-top: 30px;">
            <div class="col-sm-4 my-auto">
              <div class="input-label">Length</div>
              <input class="general-input form-control" placeholder="Length" type="text" v-model="longitude">
              <!-- <div class="col-form-label pos-absolute invalid col-sm-12">Longitude must be a number</div> -->
            </div>

            <div class="col-sm-4 my-auto">
              <div class="input-label">Latitude</div>
              <input class="general-input form-control" placeholder="Latitude" type="text" v-model="latitude">
              <!-- <div class="col-form-label pos-absolute invalid col-sm-12">Latitude must be a number</div> -->
            </div>

            <div class="col-sm-4 my-auto">
              <div class="input-label">Depth</div>
              <input class="general-input form-control" placeholder="Depth" type="text" v-model="depth">
              <!-- <div class="col-form-label pos-absolute invalid col-sm-12">Depth must be a number</div> -->
            </div>        
          </div>

          <div class="row">
            <div class="col-sm-3"></div>
            <div class="col-sm-6 text-center">
              <!-- Aquí debería enlazar a inputMap -->
              <div class="button-standard" v-on:click="navigate(10)">Select on map</div>
            </div>
            <div class="col-sm-3"></div>
          </div>
          </div>
        </div>
        <div class="col-sm-1 my-auto text-center input-arrow" v-on:click="navigate(3)"><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
  <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
</svg></div>
      </div>
    </div>
  </div>
</template>

<script>
// Vuex
import { mapGetters } from "vuex";

// Components and services
import navigation from '@/components/process-steps/navigation.vue'
import { required, decimal, maxValue } from 'vuelidate/lib/validators'
// import { getBathymetry } from '@/services/api'

export default {
  name: 'input2PuntosMuestreo',
  components: {
    navigation
  },
  computed: {
    ...mapGetters({
      status: 'ui/status', 
      current_interface: 'ui/current_interface',
    }),    
    longitude: {
          set (data) { this.$store.dispatch('dsptool/change_longitude', data); },
          get () { return this.$store.state.dsptool.formInputs.longitude }
    },
    latitude: {
          set (data) { this.$store.dispatch('dsptool/change_latitude', data); },
          get () { return this.$store.state.dsptool.formInputs.latitude }
    },
    depth: {
          set (data) { this.$store.dispatch('dsptool/change_depth', data); },
          get () { return this.$store.state.dsptool.formInputs.depth }
    },
  },
  data() {
    return {

    }
  },

  created () {

  },
  mounted () {

  },
  methods: {
    navigate: function (target) {
      this.$store.dispatch('ui/change_current_interface', target)
    },
    closeInputs: function () {
      this.$store.dispatch('ui/change_current_interface', 0)
    }
  }
}
</script>