<template>
  <div>
    <Plotly :data="data" :layout="layout" :scrollZoom="true" :displaylogo="false" :modeBarButtonsToRemove="['resetScale2d', 'hoverCompareCartesian', 'hoverClosestCartesian']"></Plotly>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { Plotly } from 'vue-plotly'

export default {
  name: 'resultsGraphDFT2',
  components: {
    Plotly
  },
  props: {
    height: Number,
    selected_file: Number
  },
  data () {
    return {
      data: [
        // ----- Gráfico de archivo seleccionado ------
        {
          x: [],
          y: [],
          name: 'wav',
          mode: 'lines',
          hoverinfo: 'skip',
          line: {
            color: 'rgba(55, 210, 242, 0.7)',
            width: 1
          },
        }
      ],
      layout:{
        yaxis: {
          //autorange: 'reversed',
          title: {
            text: 'DFT [dB re V/raíz(Hz)]'
          },
          type: 'log',
          autorange: true,
          showexponent: 'all',
          exponentformat: 'power'
        },
        xaxis: {
          title: {
            text: 'Frequency [Hz]'
          },
          type: 'log',
          autorange: true,
          showexponent: 'all',
          exponentformat: 'power'
        },
        plot_bgcolor:"#052030",
        paper_bgcolor:"#FFF3",
        dragmode: 'pan',
        modebar: {
          bgcolor: 'rgba(0,0,0,0)',
          color: '#555555',
          activecolor: '#bbbbbb',
          orientation: 'v'
        },
        height: 340,
        margin: {
          t: 10,
          r: 160
        },
        hoverlabel: {
          font: {
            color: 'white'
          }
        }
      },
      y_min: 0,
      y_max: 0
    } 
  },
  computed: {
    ...mapGetters({
      results_DFT: 'dsptool/results_DFT'
    }),
  },
  created () {
    this.layout.height = this.height
  },
  mounted () {
    this.drawGraph()
    this.drawSubplots()
  },
  watch: {
    selected_file: function () {
      this.drawGraph()
      this.drawSubplots()
    },
  },
  methods: {
    drawGraph: function () {
      this.data[0].x = this.results_DFT.f
      this.data[0].y = this.results_DFT.Y[this.selected_file]
    },
    drawSubplots: function () {
      for(let i = this.data.length -1; i> 0; i --) {
        this.data.pop()
      }
      this.results_DFT.selec_frec.forEach(frec => {
        
        let subplot = {}
        subplot.x = this.results_DFT.f
        subplot.y = this.results_DFT.xlist[frec][this.selected_file]
        subplot.name = 'frec ' + frec
        subplot.hoverinfo = 'skip'
        let line = {}
        //line.color = 'rgba(55, 210, 242, 0.7)'
        line.width = 1
        subplot.line = line
        
        this.data.push(subplot)

      });
    }
  }
}
</script>