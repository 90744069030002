<template>
  <div>
    <div class="card-header-1">
      <div class="card-header-title">SUMMARY</div>
      <div class="close-inputs" @click="closeInputs"></div>
    </div>
    <div class="card-body-1 fixed-height">
      <div class="row h-100">
        <div class="col-sm-1 my-auto text-center input-arrow" v-on:click="navigate(6)"><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
          <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/></svg>
        </div>
        <div class="col-sm-10">
          <div class="row text-center">
            <div class="col-sm-12 ">
              <navigation></navigation>
            </div>
          </div>

          <div class="form-col">
            <div class="row form-row">
                  <div class="form-check">
                    <label  v-if="proces_dft" class="form-check-label" for="dft-check"> <b>✔️ DFT</b></label>
                    <label v-else class="form-check-label" for="dft-check"> <b>❌ DFT</b></label>
                  </div>
                  <div v-if="proces_dft" style="margin-left: 3rem;">
                    <div class="row">
                      <div class="col">
                        <p>Default configuration. </p>
                      </div>
                    </div>
                  </div>
            </div>
                        <div class="separator" />
            <div class="row form-row">
                  <div class="form-check">
                    <label v-if="proces_spl" class="form-check-label" for="spl-check"> <b>✔️ SPL</b></label>
                    <label v-else class="form-check-label" for="spl-check"> <b>❌ SPL</b></label>
                  </div> 
                  <div v-if="proces_spl" style="margin-left: 3rem;">
                    <div class="row">
                      <div class="col">
                        <p v-if="proces_spl_data_freq_start">Freq. Initial: <b>{{proces_spl_data_freq_start}} </b></p>
                        <p v-else>Freq. Initial: <b>⚠️</b></p>
                      </div>
                      <div class="col">
                        <label v-if="proces_spl_data_freq_end">Freq. End: <b>{{proces_spl_data_freq_end}} </b></label>
                        <p v-else>Freq. End: <b>⚠️</b></p>
                      </div>
                      <div class="col">
                        <label v-if="proces_spl_data_paso" >pass: <b>{{paso_list[proces_spl_data_paso -1].nombre}}</b></label>
                        <p v-else>pass: <b>⚠️</b></p>
                      </div>
                    </div>
                    <div class="row" style="margin-top: 1rem;">
                      <div class="col">
                        <label v-if="proces_spl_data_filtro">Filter: <b>{{filtro_list[proces_spl_data_filtro -1].nombre}} </b></label>
                        <p v-else>Filter: <b>⚠️</b></p>
                      </div>
                      <div class="col">
                        <label v-if="proces_spl_data_orden">Filter Order: <b>{{proces_spl_data_orden}} </b></label>
                        <p v-else>Filter Order: <b>⚠️</b></p>
                      </div>
                    </div>
                  </div>
            </div> 
                        <div class="separator" />
            <div class="row form-row">
                  <div class="form-check">
                    <label v-if="proces_d11" class="form-check-label" for="d11-check"> <b>✔️ D11</b></label>
                    <label v-else class="form-check-label" for="d11-check"> <b>❌ D11</b></label>
                  </div> 
                  <div v-if="proces_d11" style="margin-left: 3rem;">
                    <div  class="row">
                      <div class="col" style="min-width: 285px;">
                        <label v-if="proces_d11_data_filtro" >Filter: <b>{{filtro_list[proces_d11_data_filtro -1].nombre}} </b></label>
                        <p v-else>Filter Name: <b>⚠️</b></p>
                      </div>
                      <div class="col">
                        <label v-if="proces_d11_data_orden" >Order: <b>{{proces_d11_data_orden}} </b></label>
                        <p v-else>Filter Order: <b>⚠️</b></p>
                      </div>
                    </div>
                  </div>
            </div>
            <div class="separator" />
            <div class="row form-row">
                  <div class="form-check">
                    <label  v-if="proces_espec" class="form-check-label" for="espec-check"> <b>✔️ Spectrogram</b></label>
                    <label v-else class="form-check-label" for="espec-check"> <b>❌ Spectrogram</b></label>
                  </div>
                  <div v-if="proces_espec" style="margin-left: 3rem;">
                    <div class="row">
                      <div class="col">
                        <p>Default configuration. </p>
                      </div>
                    </div>
                  </div>
            </div>
          </div>
          <div class="row justify-content-center">
              <div class="col-sm-6 text-center button-calculate" @click="submitProcessing">Process</div>
          </div>
        </div>
        <div class="col-sm-1"></div>
      </div>
    </div>
  </div>
</template>

<script>
// Vuex
import { mapGetters } from "vuex";
import navigation from '@/components/process-steps/navigation.vue'

export default {
  name: 'inputMap',
  computed: {
    ...mapGetters({
      status: 'ui/status', 
      current_interface: 'ui/current_interface',
      pos_receiver: 'urn/pos_receiver',
      proces_dft: 'dsptool/proces_dft',
      proces_spl: 'dsptool/proces_spl',
      proces_spl_data: 'dsptool/proces_spl_data',
      proces_spl_data_freq_start: 'dsptool/proces_spl_data_freq_start',
      proces_spl_data_freq_end: 'dsptool/proces_spl_data_freq_end',
      proces_spl_data_paso: 'dsptool/proces_spl_data_paso',
      proces_spl_data_filtro:'dsptool/proces_spl_data_filtro',
      proces_spl_data_orden: 'dsptool/proces_spl_data_orden',
      proces_d11:'dsptool/proces_d11',
      proces_d11_data: 'dsptool/proces_d11_data',
      proces_d11_data_filtro: 'dsptool/proces_d11_data_filtro',
      proces_d11_data_orden:'dsptool/proces_d11_data_orden',
      proces_espec:'dsptool/proces_espec',
      filtro_list: 'dsptool/filtro_list',
      paso_list: 'dsptool/paso_list',
    })
  },
  watch: {
    // proces_dft: function() {
    //   console.log(this.$store.state.dsptool.proces_dft)
    // }
  },
  components: {
    navigation
  },
  data() {
    return {
      // temp_long: null,
      // temp_lat: null,
    }
  },
  created () {

  },
  mounted () {
    // this.temp_long = this.pos_receiver.x
    // this.temp_lat = this.pos_receiver.y
  },
  methods: {
    navigate: function (target) {
      this.$store.dispatch('ui/change_current_interface', target)
    },
    closeInputs: function () {
      this.$store.dispatch('ui/change_current_interface', 0)
    },
    submitProcessing: function () {
      this.$store.dispatch('dsptool/submit_processing')
    }
  }
}
</script>

<style scoped>
.form-col{
  margin-top: 2rem;
}

.form-row {
  /* border-bottom: 2px solid grey; */
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.col{
  min-width: 12rem;
}

.form-not-valid{
  color: red
}

p {
  color: #cecece;
}
label {
  color: #cecece;
}
.form-check-label {
  color: #3a3a3a;
}
</style>