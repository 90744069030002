<template>
  <div class="results-container">
    <div class="card-header-1 header-thin">
      <div v-if="job_name" class="card-header-title">{{ job_name }}</div>
      <div v-else class="card-header-title">SUMMARY</div>
      <div class="close-inputs thin" @click="closeResults"></div>
    </div>
    <div class="card-body-1 results-container-body">
          <!-- Pestañas -->
          <div class="results-tab-container">
            <div v-if="proces_dft && loading_dft > 0" class="results-tab" v-bind:class="{ 'results-tab-selected': selected_results_tab == 1 }">DFT <div v-if="loading_dft > 0" class="mini-loader" v-bind:class="{ 'loader-green': loading_dft == 2 }"></div></div>
            <div v-if="proces_dft && loading_dft == 0" class="results-tab" @click="changeTab(1)" v-bind:class="{ 'results-tab-selected': selected_results_tab == 1 }">DFT</div>
            <div v-if="proces_spl && loading_spl > 0" class="results-tab" v-bind:class="{ 'results-tab-selected': selected_results_tab == 2 }">SPL <div v-if="loading_spl > 0" class="mini-loader" v-bind:class="{ 'loader-green': loading_spl == 2 }"></div></div>
            <div v-if="proces_spl && loading_spl == 0" class="results-tab" @click="changeTab(2)" v-bind:class="{ 'results-tab-selected': selected_results_tab == 2 }">SPL</div>          
            <div v-if="proces_d11 && loading_d11 > 0" class="results-tab" v-bind:class="{ 'results-tab-selected': selected_results_tab == 3 }">D11 <div v-if="loading_d11 > 0" class="mini-loader" v-bind:class="{ 'loader-green': loading_d11 == 2 }"></div></div>
            <div v-if="proces_d11 && loading_d11 == 0" class="results-tab" @click="changeTab(3)" v-bind:class="{ 'results-tab-selected': selected_results_tab == 3 }">D11</div>
            <div v-if="proces_espec && loading_espec > 0" class="results-tab" v-bind:class="{ 'results-tab-selected': selected_results_tab == 4 }">Spectr. <div v-if="loading_espec > 0" class="mini-loader" v-bind:class="{ 'loader-green': loading_espec == 2 }"></div></div>
            <div v-if="proces_espec && loading_espec == 0" class="results-tab" @click="changeTab(4)" v-bind:class="{ 'results-tab-selected': selected_results_tab == 4 }">Spectr.</div>
          </div>
          <!-- Graph 1 -->
          <div style="flex: 1;" ref="graph">
            <resultsGraphDFT1 v-if="graphs_visible && selected_results_tab == 1" :height="this.graphs_height" :selected_file="selected_file"></resultsGraphDFT1>
            <resultsGraphSPL1 v-if="graphs_visible && selected_results_tab == 2" :height="this.graphs_height" :selected_file="selected_file"></resultsGraphSPL1>       
            <resultsGraphD111 v-if="graphs_visible && selected_results_tab == 3" :height="this.graphs_height" :selected_file="selected_file"></resultsGraphD111>  
            <resultsGraphEspec1 v-if="graphs_visible && selected_results_tab == 4" :height="this.graphs_height" :selected_file="selected_file"></resultsGraphEspec1>  
          </div>
          <div v-if="selected_results_tab < 3 || selected_results_tab == 4" class="row pl-3 pr-3 mt-1 mb-1">
            <div style="color: #c8c8c8;">
              Signals:
              <select v-model="selected_file" class="select-input limited-width">
                <option v-for="(file, index) in fileNames" v-bind:key="index" v-bind:value="index">{{ file }}</option>
              </select>
            </div>
          </div> 
          <div v-if="proces_spl" class="separator"></div>
          <div v-else style="height: 10px;"></div>

          <!-- Graph 2 -->
          <!-- <div style="flex: 1;" v-if="graphs_visible && (selected_results_tab == 2 || selected_results_tab == 3 || (selected_results_tab == 1 && proces_spl))"> -->
          <div style="flex: 1;">
            <resultsGraphDFT2 v-if="graphs_visible && selected_results_tab == 1 && proces_spl" :height="this.graphs_height" :selected_file="selected_file"></resultsGraphDFT2>
            <resultsGraphSPL2 v-if="graphs_visible && selected_results_tab == 2" :height="this.graphs_height" :selected_file="selected_file"></resultsGraphSPL2> 
            <resultsGraphD112 v-if="graphs_visible && selected_results_tab == 3" :height="this.graphs_height" :selected_file="selected_file"></resultsGraphD112> 
          </div>

          <!-- Bottom row options -->
          <!-- <div class="row pl-3 pr-3">
            <div>
              Señales:
              <select v-model="selected_file" class="select-input limited-width">
                <option v-for="(file, index) in fileNames" v-bind:key="index" v-bind:value="index">{{ file }}</option>
              </select>
            </div>

            <div class="ml-auto" v-if="!job_stored">
              <input class="general-input" placeholder="Simulation name" type="text" v-model="job_name" style="width: auto;">
              <span class="button-standard" v-on:click="storeJob">Save simulation</span>
            </div>
          </div> -->


    </div> <!-- Close card-body -->
  </div> <!-- Close component div wrapper -->      
</template>

<script>
// Vuex
import { mapGetters } from "vuex"

// Components and services
import resultsGraphDFT1 from '@/components/process-steps/resultsGraphDFT1.vue'
import resultsGraphDFT2 from '@/components/process-steps/resultsGraphDFT2.vue'
import resultsGraphSPL1 from '@/components/process-steps/resultsGraphSPL1.vue'
import resultsGraphSPL2 from '@/components/process-steps/resultsGraphSPL2.vue'
import resultsGraphD111 from '@/components/process-steps/resultsGraphD111.vue'
import resultsGraphD112 from '@/components/process-steps/resultsGraphD112.vue'
import resultsGraphEspec1 from '@/components/process-steps/resultsGraphEspec1.vue'
import { postData } from '@/services/api'

export default {
  name: 'resultsContainer',
  components: {
    resultsGraphDFT1,
    resultsGraphDFT2,
    resultsGraphSPL1,
    resultsGraphSPL2,
    resultsGraphD111,
    resultsGraphD112,
    resultsGraphEspec1,
  },
  data() {
    return {
      graphs_visible: false,
      graphs_height: 200
    }
  },
  computed: {
    ...mapGetters({
      job_id: 'dsptool/job_id',
      job_name: 'dsptool/name',
      fileNames: 'dsptool/fileNames',
      proces_dft: 'dsptool/proces_dft',
      proces_spl: 'dsptool/proces_spl',
      proces_d11: 'dsptool/proces_d11',
      proces_espec: 'dsptool/proces_espec',
      loading_dft: 'ui/loading_dft',
      loading_spl: 'ui/loading_spl',
      loading_d11: 'ui/loading_d11',
      loading_espec: 'ui/loading_espec',
      //results_DFT: 'dsptool/results_DFT',
      //job_stored: 'urn/job_stored'
    }),
    selected_file: {
        set (val) { this.$store.dispatch('dsptool/change_selected_file', val); },
        get () { return this.$store.state.dsptool.selected_file }
    },
    selected_results_tab: {
        set (val) { this.$store.dispatch('dsptool/change_selected_results_tab', val); },
        get () { return this.$store.state.dsptool.selected_results_tab }
    },
  },
  created() {
    window.addEventListener("resize", this.resizeWindow)
  },
  mounted() {
    this.setGraphsHeight()
  },
  methods: {
    storeJob: function () {
      const formData = new FormData()
      formData.append("job_id", this.job_id)
      if(this.job_name) {
        formData.append("job_name", this.job_name)
      }
      postData("noisemodels/store/", formData).then(res => {
        if (res.status == 200 && res.data.Stored == "ok") {
          this.$noty.success("Simulation results stored")
          this.$store.dispatch('urn/change_job_stored', true)
          if(!this.job_name) this.job_name = 'Simulation ' + this.job_id
          this.$store.dispatch('urn/get_job_list')
        } 
        else {
          this.$noty.error("Simulation could not be stored")
        }
      }).catch(error => {
        this.$noty.error("Simulation could not be stored")
      });
    },
    setGraphsHeight: function () {
      //console.log(this.$refs.graph.clientHeight)
      this.graphs_height = this.$refs.graph.clientHeight
      this.graphs_visible = true
    },
    resizeWindow: function () {
      globalThis = this
      this.graphs_visible = false
      clearTimeout(window.resizedFinished);
      window.resizedFinished = setTimeout(function(){
          globalThis.setGraphsHeight()
      }, 150);
    },
    changeTab: function (tab) {
      this.selected_results_tab = tab
    },
    closeResults: function () {
      this.$store.dispatch('dsptool/reset')   
      this.$store.dispatch('dsptool/change_update_jobs_list', true)
      this.$store.dispatch('ui/change_current_interface', 0)
    }
  }
}
</script>
<style scoped>
.limited-width {
  width: 200px;
  margin-left: 15px;
}
.separator {
  height: 8px;
  width: 100%;
  margin-bottom: 12px;
  border-bottom: 1px solid #595959;
}
.results-tab-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  left: -100px;
  width: 100px;
}
.results-tab {
  background-color: #aaaaaa;
  padding: 10px 5px;
  border-top: 1px solid #888888;
  border-bottom: 1px solid #888888;
  border-left: 1px solid #888888;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  cursor: pointer;
}
.results-tab-selected {
  background-color: rgba(255, 255, 255, 0.85);
}
</style>