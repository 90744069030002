<template>
  <div>
    <Plotly :data="data" :layout="layout" :scrollZoom="true" :displaylogo="false" :modeBarButtonsToRemove="['resetScale2d', 'hoverCompareCartesian', 'hoverClosestCartesian']"></Plotly>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { Plotly } from 'vue-plotly'

export default {
  name: 'resultsGraphSPL1',
  components: {
    Plotly
  },
  props: {
    height: Number,
    selected_file: Number
  },
  data () {
    return {
      data: [
        // ----- Gráfico de archivo seleccionado ------
        {
          x: [],
          y: [],
          name: 'wav',
          mode: 'lines',
          hoverinfo: 'skip',
          line: {
            color: 'rgba(55, 210, 242, 0.7)',
            width: 1
          },
        },
        // ----- Gráfico con la media de los wavs ------
      ],
      layout:{
        yaxis: {
          //autorange: 'reversed',
          title: {
            text: 'SPL [dB re 1μPa]'
          },
          // type: 'log',
          // autorange: true,
          // showexponent: 'all',
          // exponentformat: 'power'
        },
        xaxis: {
          title: {
            text: 'Frequency [Hz]'
          },
          type: 'log',
          autorange: true,
          showexponent: 'all',
          exponentformat: 'power'
        },
        plot_bgcolor:"#052030",
        paper_bgcolor:"#FFF3",
        dragmode: 'pan',
        modebar: {
          bgcolor: 'rgba(0,0,0,0)',
          color: '#555555',
          activecolor: '#bbbbbb',
          orientation: 'v'
        },
        height: 340,
        margin: {
          t: 10,
          r: 160
        },
        hoverlabel: {
          font: {
            color: 'white'
          }
        }
      },
      y_min: 0,
      y_max: 0
    } 
  },
  computed: {
    ...mapGetters({
      results_SPL: 'dsptool/results_SPL'
    }),
  },
  created () {
    this.layout.height = this.height
  },
  mounted () {
    this.drawGraph()
  },
  watch: {
    selected_file: function () {
      this.drawGraph()
    },
  },
  methods: {
    drawGraph: function () {
      this.data[0].x = this.results_SPL.freq_SPL
      this.data[0].y = this.results_SPL.SPL[this.selected_file]
    }
  }
}
</script>