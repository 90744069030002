<template>
  <div class="container-fluid p-0 home-bg">
    <div id="map" class="map-container"></div>
    <div class="pt-2 row h-100">
      <div class="col-sm-4"></div>
      <router-link  class="button-new-job" tag="div" to="/dsptool" exact>
              Start DSPTool
            </router-link>
      <div class="col-sm-4 my-auto">
        <div>
        </div>
      </div>
      <div class="col-sm-4"></div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import {postData} from '@/services/api'
import "leaflet/dist/leaflet.css"
import L from "leaflet"

export default {
  name: 'Home',
  created () {
  },
  methods: {
    // redirect(location){
    //   if(location == 'URN') this.$router.push('/urn')
    // },
    setBaseMap: function () {
      this.map = L.map("map", {zoomControl: false}).setView([38.959, 12.623], 6);
      L.tileLayer(
        "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
        {
          attribution:
            '&copy; <a href="https://www.ctninnova.com/en/">Centro Tecnológico Naval y del Mar</a>',
        }
      ).addTo(this.map)
      L.control.scale({imperial: false}).addTo(this.map)
    },
  },
  data() {
    return {
      map: null,
      // icon_emitter: {},
      // icon_receiver: {},
      // marker_emitter: null,
      // marker_receiver: null,
      // marker_transect: null,
    }
  },
  mounted () {
    this.setBaseMap()
    // this.loadIcons()
  },

}
</script>
